.promotional-landing {
  margin-bottom: -40px;
}
.promotional-landing .btn-default {
  border-color: #2174c8;
  color: #2174c8;
  border-style: solid;
  font-weight: 900;
  font-size: 1.5rem;
}
.promotional-landing .col-xs-1, .promotional-landing .col-xs-2, .promotional-landing .col-xs-3, .promotional-landing .col-xs-4, .promotional-landing .col-xs-5, .promotional-landing .col-xs-6, .promotional-landing .col-xs-7, .promotional-landing .col-xs-8, .promotional-landing .col-xs-9, .promotional-landing .col-xs-10, .promotional-landing .col-xs-11, .promotional-landing .col-xs-12 {
  float: none;
}
.promotional-landing .container {
  width: 100%;
  max-width: 1600px;
}
.promotional-landing .headline-outer-wrap {
  max-width: 100%;
}

.hero.hero-promo {
  height: auto;
}
.hero.hero-promo .hero__content {
  background: none;
  color: white;
  max-width: 65ch;
  text-align: center;
  margin: auto;
}
.hero.hero-promo .hero__wrapper {
  position: relative;
}
.hero.hero-promo .hero__wrapper .centered-hero-content {
  top: auto;
  transform: none;
  padding-block: 8vw;
}
.hero.hero-promo .hero__bg.hero-promo__bg {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  transform: none;
}
.hero.hero-promo .hero-promo__heading {
  position: relative;
  color: #fff;
  bottom: auto;
  top: auto;
  background: none !important;
  opacity: 1;
  font-size: 5rem;
  line-height: 5rem;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
  word-wrap: break-word;
}

.promo-grid:nth-child(even) {
  background-color: #f4f4f4;
}
.promo-grid:nth-child(even) .promo-load, .promo-grid:nth-child(even) .promo-collapse {
  background: #2174c8;
  color: white;
}
.promo-grid:nth-child(odd) {
  background-color: #ffffff;
}
.promo-grid:nth-child(odd) .promo-load, .promo-grid:nth-child(odd) .promo-collapse {
  background: none;
  color: #2174c8;
}
.promo-grid__btn {
  align-self: end;
  text-align: center;
  width: 100%;
}
.promo-grid__title {
  font-size: 2.75rem;
  line-height: 2.75rem;
}
.promo-grid__img {
  width: 100%;
  max-width: 100%;
}
.promo-grid__img img {
  width: 100%;
  max-width: 100%;
}
.promo-grid__list {
  display: grid;
  grid-template-columns: minmax(200px, 1fr);
  padding-left: 0;
  gap: 1rem;
  list-style-type: none;
  margin-inline: auto;
  padding-inline: 15px;
  row-gap: 2rem;
}
@media (min-width: 768px) {
  .promo-grid__list {
    max-width: 970px;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}
@media (min-width: 1200px) {
  .promo-grid__list {
    max-width: 1600px;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    row-gap: 4rem;
  }
}
@media (max-width: 768px) {
  .promo-grid__list {
    grid-template-columns: minmax(200px, 1fr);
    margin-bottom: 2rem;
    max-width: 750px;
  }
}
.promo-grid__item {
  padding-inline: 1rem;
  display: grid;
  flex-flow: column;
  grid-template-columns: minmax(200px, 1fr);
  gap: 1rem;
}

.guide-block__header {
  text-align: center;
  margin-bottom: 4rem;
  margin-inline: auto;
  padding-inline: 2rem;
}
@media (max-width: 768px) {
  .guide-block__header {
    margin-bottom: 2rem;
    max-width: 750px;
  }
}
@media (max-width: 992px) {
  .guide-block__header {
    max-width: 970px;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1200px) {
  .guide-block__header {
    max-width: 1600px;
  }
}
.guide-block__img {
  max-width: 100%;
}
.guide-block__img img {
  max-width: 100%;
  margin-bottom: 1rem;
  display: block;
}
.guide-block__item {
  width: calc(50% - 2rem);
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .guide-block__item {
    width: 100%;
  }
}
.guide-block__cta {
  text-align: center;
  margin-inline: auto;
}

.guide-grid {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  gap: 4rem;
  padding-left: 0;
  margin-inline: auto;
  padding-inline: 15px;
}
@media (min-width: 992px) {
  .guide-grid {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .guide-grid {
    max-width: 1600px;
  }
}
@media (max-width: 768px) {
  .guide-grid {
    max-width: 750px;
  }
}

.promo-header {
  text-align: center;
  margin-bottom: 4rem;
}
@media (max-width: 768px) {
  .promo-header {
    margin-bottom: 2rem;
    padding-inline: 2rem;
  }
}

.promo-load, .promo-collapse {
  margin-inline: auto;
}

.promo-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 4rem;
}
@media (max-width: 768px) {
  .promo-buttons {
    margin-top: 2rem;
  }
}

.promo-form {
  background-color: #f4f4f4;
  padding-inline: 2rem;
}
.promo-form-container {
  max-width: 800px;
  margin-inline: auto;
}
.promo-form form {
  max-width: 600px;
  margin-inline: auto;
}
.promo-form .form-group {
  text-align: left;
}
.promo-form-header, .promo-form-container {
  text-align: center;
}