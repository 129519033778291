$mobile-breakpoint: 768px;
$tablet-breakpoint: 992px;
$desktop-breakpoint: 1200px;
$spartan-blue: #2174c8;

.promotional-landing {
	margin-bottom: -40px;

	.btn-default {
		border-color: $spartan-blue;
		color: $spartan-blue;
		border-style: solid;
		font-weight: 900;
		font-size: 1.5rem;
	}

	.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
		float: none;
	}

	.container {
		width: 100%;
		max-width: 1600px;
	}

	.headline-outer-wrap {
		max-width: 100%;
	}
}
.hero.hero-promo {
	height: auto;

	.hero__content {
		background: none;
		color: white;
		max-width: 65ch;
		text-align: center;
		margin: auto;
	}

	.hero__wrapper {
		position: relative;

		.centered-hero-content {
			top: auto;
			transform: none;
			padding-block: 8vw;
		}
	}

	.hero__bg.hero-promo__bg {
		object-fit: cover;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		transform: none;
	}

	.hero-promo__heading {
		position: relative;
		color: #fff;
		bottom: auto;
		top: auto;
		background: none !important;
		opacity: 1;
		font-size: 5rem;
		line-height: 5rem;
		padding: 0;
		margin: 0;
		margin-bottom: 2rem;
		word-wrap: break-word;
	}
}


.promo-grid {
	&:nth-child(even) {
		background-color: #f4f4f4;

		.promo-load, .promo-collapse {
			background: $spartan-blue;
			color: white;
		}
	}



	&:nth-child(odd) {
		background-color: #ffffff;

		.promo-load, .promo-collapse {
			background: none;
			color: $spartan-blue;
		}
	}

	&__btn {
		align-self: end;
		text-align: center;
		width: 100%;
	}

	&__title {
		font-size: 2.75rem;
		line-height: 2.75rem;
	}

	&__img {
		width: 100%;
		max-width: 100%;

		img {
			width: 100%;
			max-width: 100%;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: minmax(200px, 1fr);
		padding-left: 0;
		gap: 1rem;
		list-style-type: none;
		margin-inline: auto;
		padding-inline: 15px;
		row-gap: 2rem;

		@media (min-width: $mobile-breakpoint) {
			max-width: 970px;
			grid-template-columns: repeat(2, minmax(300px, 1fr));
		}

		@media (min-width: $desktop-breakpoint) {
			max-width: 1600px;
			grid-template-columns: repeat(3, minmax(300px, 1fr));
			row-gap: 4rem;
		}

		@media (max-width: $mobile-breakpoint) {
			grid-template-columns: minmax(200px, 1fr);
			margin-bottom: 2rem;
			max-width: 750px;
		}
	}

	&__item {
		padding-inline: 1rem;
		display: grid;
		flex-flow: column;
		grid-template-columns: minmax(200px, 1fr);
		gap: 1rem;
	}
}

	

.guide-block {
	&__header {
		text-align: center;
		margin-bottom: 4rem;
		margin-inline: auto;
		padding-inline: 2rem;

		@media (max-width: $mobile-breakpoint) {
			margin-bottom: 2rem;
			max-width: 750px;
		}


		@media (max-width: $tablet-breakpoint) {
			max-width: 970px;
			margin-bottom: 2rem;
		}

		@media (min-width: $desktop-breakpoint) {
			max-width: 1600px;
		}
	}

	&__img {
		max-width: 100%;

		img {
			max-width: 100%;
			margin-bottom: 1rem;
			display: block;
		}
	}

	&__item {
		width: calc(50% - 2rem);
		display: flex;
		flex-flow: column;
		gap: 1rem;
		justify-content: space-between;

		@media (max-width: $mobile-breakpoint) {
			width: 100%;
		}
	}

	&__cta {
		text-align: center;
		margin-inline: auto;
	}
}

	.guide-grid {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: center;
		list-style-type: none;
		gap: 4rem;
		padding-left: 0;
		margin-inline: auto;
		padding-inline: 15px;

		@media (min-width: $tablet-breakpoint) {
			max-width: 970px;
		}

		@media (min-width: $desktop-breakpoint) {
			max-width: 1600px;
		}

		@media (max-width: $mobile-breakpoint) {
			max-width: 750px;
		}
	}



	.promo-header {
		text-align: center;
		margin-bottom: 4rem;

		@media (max-width: $mobile-breakpoint) {
			margin-bottom: 2rem;
			padding-inline:2rem;
		}
	}

	.promo-load, .promo-collapse {
		margin-inline: auto;
	}

	.promo-buttons {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin-top: 4rem;

		@media (max-width: $mobile-breakpoint) {
			margin-top: 2rem;
		}
	}

	.promo-form {
		background-color: #f4f4f4;
		padding-inline:2rem;
		&-container {
			max-width: 800px;
			margin-inline: auto;
		}

		form {
			max-width: 600px;
			margin-inline: auto;
		}

		.form-group {
			text-align: left;
		}

		&-header, &-container {
			text-align: center;
		}
	}
